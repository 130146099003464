export const dictionary: Record<string, string> = {
  header: 'Добавление художника:',
  lastName: 'Фамилия',
  firstName: 'Имя',
  patronymic: 'Отчество',
  birthDate: 'Дата рождения',
  birthPlace: 'Место рождения',
  deathDate: 'Дата смерти',
  deathPlace: 'Место смерти',
  artMovements: 'Направления',
  otherInfo: 'Дополнительная информация',
  wikiUrl: 'Ссылка на Wiki',
  isArtist: 'Художник',
  reset: 'Очистить',
  save: 'Сохранить',
  success: 'Художник успешно добавлен!',
};
