import { Box, Typography } from "@mui/material"
import { styles } from "./styles"

export const About = () => {
    return (
        <Box sx={styles.wrapper}>
            <Typography variant='h4' mb={4} mt={10} textAlign='center'>
                О нас
            </Typography>

            <Typography variant='h6' mb={4} mt={10} textAlign='center'>
                Некоторые перспективы
            </Typography>

            <Typography variant='body1' mb={2}>
                На данном этапе наш проект лишь проверял гипотезу о жизнеспособности сетевого моделирования в отношении изучения взаимосвязей художников авангардного движения. Следующим этапом мы планируем расширять нашу базу данных и вводить более обширную систему категорий, по которым можно выделять группы связей или отдельные сущности. Ниже приведен список возможных направлений работы при разработке и создании цифрового проекта в дальнейшем: 
            </Typography>

            <Typography variant='body1' mb={1} ml={4}>
                - разобрать “жизнь” художественных объединений на этапы и отдельные выставки, так как многие из выявленных нами художественных групп распадались, собирались обратно, почти полностью меняли состав за время официального существования;
            </Typography>

            <Typography variant='body1' mb={1} ml={4}>
                - поиск институций для сотрудничества в формате получения доступа к большим базам данных. Возможно, разработка системы оцифровки и сбора информации;
            </Typography>
            
            <Typography variant='body1' mb={1} ml={4}>
                - изучение синтеза искусств и взаимодействия представителей разных профессий в результате  расширения базы данных литераторами, поэтами, музыкантами, меценатами и т.д.; Также это позволит найти мосты между некоторыми объединениями и персоналиями; 
            </Typography>

            <Typography variant='body1' mb={1} ml={4}>
                - расширение базы данных “зарубежными” деятелями искусства, которые были связаны с Россией и русским авангардом (по принципам гражданства, места жительства, участия в объединениях, совместных выставках и т.д.);
            </Typography>

            <Typography variant='body1' mb={1} ml={4}>
                - разработка полноценного UX дизайна графа для корректного отображения важных визуальных нюансов, расширения навигации, фильтрации и, в целом, возможностей работы с графом в научном ключе;
            </Typography>

            <Typography variant='body1' mb={1} ml={4}>
                - разработка таймлайна для сайта. Это позволит перемещаться по хронологической шкале и смотреть, каким подвижным был период авангарда, как на самом деле происходит поиск нового пластического языка, перебираются идеи и инструменты. Также это дает возможность наглядно увидеть жизнь объединений: как они образуются и потом угасают, разделяются на несколько новых;
            </Typography>

            <Typography variant='body1' mb={1} ml={4}>
                - на основе сформированной базы данных также можно будет создать интерактивную карту, где будут видны места концентрации деятелей искусств, выставок и т.д.;
            </Typography>

            <Typography variant='body1' mb={1} ml={4}>
                - кроме того, в дальнейшем мы рассматриваем возможность коллаборации с культурной институцией, которая сможет внести новые идеи и смыслы в дальнейшую работу гильдии (например, предложит нам провести выставку на основе проведенных исследований). 
            </Typography>
        </Box>
    )
}